import { StyledNewOrderHeading } from './styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { Box } from '@/components/uielements/box/box';
import { Stack } from '@/components/uielements/stack/stack';
import { TicketIconSvg } from '@/components/uielements/ticketIcon';

export const NewOrderHeading = () => {
  return (
    <Stack
      align="leading"
      overrides={{
        marginBottom: 'var(--size-2)',
      }}
    >
      <Box
        overrides={{
          marginTop: 'var(--size-1)',
        }}
      >
        <TicketIconSvg />
      </Box>

      <Box>
        <StyledNewOrderHeading>
          <StringAccessor accessor="reorders.new_order_title" tag="span" />
        </StyledNewOrderHeading>
        <div>
          <StringAccessor tag="span" accessor="locations.subheader" html={true} />
        </div>
      </Box>
    </Stack>
  );
};
