import { type WebConfig } from '@koala/sdk';
import { withTheme } from 'styled-components';

interface Props {
  color?: string;
  size?: number;
}

interface ThemeProps {
  theme: WebConfig;
}

const _TicketIconSvg = ({ color, size = 24, theme }: Props & ThemeProps) => {
  const fallbackColor = theme.text.primary_font.color ?? 'black';
  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.938 12.625h8.125M7.938 10.125h8.125M4.5 18.25V6.375a.625.625 0 0 1 .625-.625h13.75a.625.625 0 0 1 .625.625V18.25L17 17l-2.5 1.25L12 17l-2.5 1.25L7 17l-2.5 1.25Z"
        stroke={color ?? fallbackColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TicketIconSvg = withTheme(_TicketIconSvg);
