import { type FC } from 'react';
import { type Location } from '@koala/sdk';
import { LocationCard } from '@/components/locations/card';
import { type ILocationList } from '@/types/locations';

const List: FC<ILocationList> = ({ locations, setActiveLocation, activeLocationId, menuCta }) => {
  return (
    <>
      {locations &&
        locations.length > 0 &&
        locations.map((location: Location) => (
          <LocationCard
            key={location.id}
            activeLocationId={activeLocationId}
            location={location}
            setActiveLocation={setActiveLocation}
            menuCta={menuCta}
          />
        ))}
    </>
  );
};

export default List;
