import { type DeliveryAddress, type WebConfig } from '@koala/sdk';
import { Fragment, useEffect } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { StyledAddressContainer, StyledDeliveryFormSubheader } from './styles';
import PrimaryButton from '@/components/checkout/uielements/primaryButton';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { locationsActions } from '@/redux/locations/actions';
import meActions from '@/redux/me/actions';
import { type RootState } from '@/types/app';
import { userLoggedIn } from '@/utils/auth';

interface ThemeProps {
  theme: WebConfig;
}

type Props = ReduxProps & ThemeProps;

const handleSubmitAddress = (
  address: DeliveryAddress,
  searchDeliveryCoverage: (address: DeliveryAddress) => void,
) => {
  const storedAddress = Object.assign({}, address, {
    city: address.city,
    street_address: address.street_address,
    street_address_2: address.street_address_2,
    time_wanted: 'asap',
    zip_code: address.zip_code,
  });

  storedAddress.id = address.id;

  searchDeliveryCoverage(storedAddress);
};

const _DeliverySearchStoredAddresses = ({
  myAddresses,
  fetchAddresses,
  searchDeliveryCoverage,
  theme,
}: Props) => {
  // Don't show section if feature disabled
  if (!theme.accounts?.stored_addresses) {
    return null;
  }

  useEffect(() => {
    if (userLoggedIn()) {
      fetchAddresses();
    }
  }, []);

  // Don't show section if no stored addresses
  if (myAddresses?.length < 1) {
    return null;
  }

  return (
    <>
      <StringAccessor
        tag={StyledDeliveryFormSubheader}
        accessor="account.addresses_header"
        html={true}
      />
      {myAddresses.map((address) => (
        <Fragment key={address.id}>
          <StyledAddressContainer>
            <p>
              {/* Address details */}
              {address.street_address}
              {address.street_address_2 && <>,&nbsp;{address.street_address_2}</>}
              ,&nbsp;{address.city}, {address?.zip_code}
            </p>
            <PrimaryButton
              type="submit"
              theme="primary"
              $submitting={false}
              handleClick={() => handleSubmitAddress(address, searchDeliveryCoverage)}
              aria-label={`Order delivery to ${address.street_address} ${address.street_address_2} ${address.city}`}
            >
              <StringAccessor accessor="locations.delivery_search_addresses_cta" />
            </PrimaryButton>
          </StyledAddressContainer>
        </Fragment>
      ))}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  myAddresses: state.app.me.myAddresses,
});

const mapDispatchToProps = {
  fetchAddresses: meActions.fetchAddresses,
  searchDeliveryCoverage: locationsActions.searchDeliveryCoverage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export const DeliverySearchStoredAddresses = compose<any>(
  withTheme,
  connector,
)(_DeliverySearchStoredAddresses);
