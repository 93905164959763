import { StyledAnimatedBlock } from './styles';

interface AnimatedBlockProps {
  children?: React.ReactNode;
  className?: string;
  role?: string;
}

const AnimatedBlockComponent = ({ children, className, role }: AnimatedBlockProps) => (
  <StyledAnimatedBlock className={className} role={role}>
    {children}
  </StyledAnimatedBlock>
);

export default AnimatedBlockComponent;
