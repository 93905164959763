import styled from 'styled-components';
import { LAYOUT } from '@/constants/styles';

export const StyledLocationGroup = styled.div`
  background: none;
  border-top: 1px solid ${({ theme }) => theme.global.primary_border_color};
`;

interface StyledLocationGroupTriggerProps {
  $isActive: boolean;
}

export const StyledLocationGroupTrigger = styled.button<StyledLocationGroupTriggerProps>`
  background: ${(props: { $isActive: boolean }) => (props.$isActive ? 'none' : 'none')};
  border: none;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.global.primary_active_color : theme.text.secondary_font.color};
  cursor: pointer;
  font-family: ${({ theme }) => theme.primary_font.medium.font_family};
  font-size: 20px;
  padding: ${LAYOUT.GUTTER}px;
  position: relative;
  text-align: left;
  width: 100%;

  &:after {
    color: ${({ $isActive, theme }) =>
      $isActive ? theme.global.primary_active_color : theme.text.secondary_font.color};
    content: '›';
    position: absolute;
    right: ${LAYOUT.GUTTER}px;
    top: calc(50% - 11px);
    transform: rotate(${(props: { $isActive: boolean }) => (props.$isActive ? '-90' : '90')}deg);
  }
`;

export const StyledLocationGroupLocations = styled.div`
  background: none;
`;
