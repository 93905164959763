import { CONVEYANCE_TYPES, type LocationSearchQuery, type GeoIp } from '@koala/sdk';
import { geoIpLookup } from '@koala/sdk/v4';
import get from 'lodash/get';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
import { type ComponentType, useEffect, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { genericEventHandler } from '@/analytics/events';
import { EventNames, GlobalEvents } from '@/analytics/events/constants';
import { LocationsRewardsSummary } from '@/components/account/locationRewardsSummary';
import LoyaltyAccessor from '@/components/account/loyaltyAccessor';
import Layout from '@/components/app/layout';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { FeatureAccessor } from '@/components/featureAccessor';
import { LocationsHeading } from '@/components/locations/heading';
import {
  LayoutListSection,
  LoadingIndicator,
  LocationsHandoffToggle,
  LocationsHandoffToggleButton,
  LocationsLayout,
  LocationsLayoutList,
  LocationsLayoutMap,
  LocationsNoResults,
  LocationsViewAll,
  LocationsReorders,
  LocationsDeliverySearch,
} from '@/components/locations/layout/styles';
import ResultsList from '@/components/locations/results';
import Search from '@/components/locations/search';
import DeliverySearch from '@/components/locations/search/deliverySearch';
import { ReorderList } from '@/components/reorders/list';
import { NewOrderHeading } from '@/components/reorders/newOrderHeading';
import { StyledHr } from '@/components/uielements/hr';
import { Render } from '@/components/uielements/render';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { FEATURE_FLAGS } from '@/constants/features';
import { LOCATION_OPTIONS, LOCATION_VIEW_STATES, LOCATIONS_CONFIG } from '@/constants/locations';
import { LOYALTY_ACCESSOR_TYPES, LOYALTY_FEATURES } from '@/constants/loyalty';
import { LAYOUT } from '@/constants/styles';
import { locationsActions } from '@/redux/locations/actions';
import { createHttpClient } from '@/services/client';
import { type RootState } from '@/types/app';
import { getOrigin } from '@/utils';
import { isAndroidShell } from '@/utils/android';
import * as ErrorReporter from '@/utils/errorReporter';
import { MagicBoxParams } from '@/utils/magicBox';
import { pluralizeString, safelyGetConfig } from '@/utils/stringHelpers';
import { FixOnScroll } from '@/components/app/fixOnScroll';

let androidGeolocationRequestTimeout = 0;

const LocationsFinder = ({
  organization,
  activeLocationId,
  params,
  meta,
  loading,
  searchLoading,
  moreLocationsLoading,
  searchTimestamp,
  viewState,
  list,
  fetchLocations,
  fetchAllLocations,
  searchLocations,
  setActiveLocation,
  clearLocations,
  webConfig,
  data,
}: ReduxProps) => {
  const [clickedMapSearch, setClickedMapSearch] = useState(false);
  const [hideMapButton, setHideMapButton] = useState(false);
  const [handoffMode, setHandoffMode] = useState<CONVEYANCE_TYPES>(CONVEYANCE_TYPES.PICKUP);

  /**
   * This is a huge hack to fix a hydration mismatch error with React 18.
   * Basically, it forces the Google Map to render client-side rather than
   * attempting to render it server-side and hydrate on the client.
   *
   * This is currently necessary because the Map needs a big refactor
   * and there are some underlying issues with the
   * `@react-google-maps/api` library that need further investigation.
   */
  const getImport = async () => {
    try {
      const component = await import('@/components/locations/map/google');
      return component.default as ComponentType<any>;
    } catch (e) {
      console.error(e);
      throw e; // Re-throwing the error to handle it outside
    }
  };
  const ClientSideMap = dynamic(() => getImport(), { ssr: false });

  const createAndroidGeolocationRequestTimeout = () => {
    androidGeolocationRequestTimeout = window.setTimeout(() => {
      // fallback to IP geolocation after a timeout
      getNearbyLocations();
    }, 5000);
  };

  const getNearbyLocations = () => {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    geoIpLookup({ client })
      .then((data: GeoIp) => {
        const geoData = data.geoData;

        if (geoData) {
          genericEventHandler(GlobalEvents.LOCATION_RECOMMENDATIONS_SURFACED, {
            name: EventNames.LOCATIONS_SURFACED_IPADDRESS,
          });

          onSearch({
            distance: LOCATION_OPTIONS.defaultSearchRadius,
            latitude: geoData.ll[0],
            longitude: geoData.ll[1],
          });
        } else {
          genericEventHandler(GlobalEvents.LOCATION_RECOMMENDATIONS_SURFACED, {
            name: EventNames.LOCATIONS_SURFACED_DEFAULT,
          });

          getLocations();
        }
      })
      .catch(() => getLocations());
  };

  useEffect(() => {
    if (isAndroidShell()) {
      try {
        // getGeolocation can fail if the Android app has not given geolocation permissions
        window.KoalaAndroidShell.getGeolocation();
        window.addEventListener(
          'android/geolocationUpdated',
          maybeReceivedAndroidGeoLocation as EventListener,
        );
        createAndroidGeolocationRequestTimeout();
      } catch (error) {
        ErrorReporter.captureException(error);
        // fallback to IP geolocation after an error
        getNearbyLocations();
      }
    } else {
      getNearbyLocations();
    }

    return () => {
      clearLocations();
      window.removeEventListener(
        'android/geolocationUpdated',
        maybeReceivedAndroidGeoLocation as EventListener,
      );
    };
  }, []);

  useEffect(() => {
    genericEventHandler(GlobalEvents.DELIVERY__TOGGLE, { name: handoffMode });
  }, [handoffMode]);

  const maybeReceivedAndroidGeoLocation = (
    event: CustomEvent<{
      location: {
        lat?: number;
        lng?: number;
      };
    }>,
  ) => {
    clearTimeout(androidGeolocationRequestTimeout);
    const { lat, lng } = event?.detail.location;

    // attempt to read the device latitude and longitude
    if (lat && lng) {
      genericEventHandler(GlobalEvents.LOCATION_RECOMMENDATIONS_SURFACED, {
        name: EventNames.LOCATIONS_SURFACED_GEOLOCATION,
      });

      onSearch({
        distance: LOCATION_OPTIONS.defaultSearchRadius,
        latitude: lat,
        longitude: lng,
      });
    } else {
      // otherwise start the normal ordering app process of using the customer's ip address
      getNearbyLocations();
    }
  };

  /** @TODO improve typing from the brand's `Map` component. */
  const fetchCenterCoords = (mapCenterCoords: any, largestRadialDistance: any) => {
    onSearch({
      distance: largestRadialDistance,
      latitude: mapCenterCoords[0],
      longitude: mapCenterCoords[1],
    });

    setClickedMapSearch(true);
  };

  // This controls the "Search this area" button visibility
  const showMapButton = () => {
    if (hideMapButton) {
      setHideMapButton(false);
    }
  };

  const getLocations = (page?: number, viewAll?: boolean) => {
    const magicBox = new MagicBoxParams()
      .setPagination(page ?? 1, 50)
      .setSorts({ state_id: 'asc', label: 'asc' })
      .setIncludes(['operating_hours', 'attributes', 'delivery_hours', 'business_hours']);

    if (viewAll) {
      fetchAllLocations(magicBox);
    } else {
      fetchLocations(magicBox);
    }

    setClickedMapSearch(false);
    setHideMapButton(true);
  };

  const handleViewAll = () => {
    // clear any previous search results (to remove the search radius heading) before viewing all
    clearLocations();

    clearTimeout(androidGeolocationRequestTimeout);

    const groupedLocations =
      safelyGetConfig(webConfig, 'locations.list_display') ===
      LOCATIONS_CONFIG.ALL_DISPLAY.GROUP_BY_STATE;

    getLocations(undefined, groupedLocations);

    genericEventHandler(GlobalEvents.LOCATIONS__VIEW_ALL_LOCATIONS);
  };

  const onSearch = (values?: LocationSearchQuery, previousAction?: string) => {
    const magicBox = new MagicBoxParams()
      .setPagination(1, 50)
      .setSorts({ state_id: 'asc' })
      .setIncludes(['operating_hours', 'attributes', 'delivery_hours', 'business_hours']);

    searchLocations(magicBox, values, previousAction, CONVEYANCE_TYPES.PICKUP);

    setClickedMapSearch(false);
    setHideMapButton(true);
  };

  const currentPage = get(meta, 'pagination.current_page') ?? 1;

  // Optional Delivery Search
  const deliverySearchEnabled = webConfig.locations.delivery_search;
  const me = data;

  return (
    <Layout disabled={webConfig.locations.disable_locations_page} pageName="locations">
      <div>
        <LocationsLayout>
          <LocationsLayoutList className={CSS_CLASSES.LOCATION_LIST.CONTAINER}>
            {me?.id ? (
              <StringAccessor tag="h1" accessor="locations.header_user" html={true} dataObj={me} />
            ) : (
              <StringAccessor tag="h1" accessor="locations.header_guest" html={true} />
            )}

            {/* Points and Rewards summary on locations page */}
            <LoyaltyAccessor
              checkType={LOYALTY_ACCESSOR_TYPES.FEATURE}
              checkName={LOYALTY_FEATURES.GET_AVAILABLE_REWARDS}
              component={<LocationsRewardsSummary />}
            />

            <FeatureAccessor
              featureFlag={FEATURE_FLAGS.ME__REORDER}
              renderFallback={
                <div style={{ margin: '20px 0 0' }}>
                  <StringAccessor tag="div" accessor="locations.subheader" html={true} />
                </div>
              }
            >
              <LocationsReorders>
                <ReorderList ordersToDisplay={5} />
              </LocationsReorders>

              <NewOrderHeading />
            </FeatureAccessor>

            {/* Optional Delivery Search Toggle */}
            <Render condition={deliverySearchEnabled}>
              <LocationsHandoffToggle
                aria-label="Handoff Toggle"
                role="tablist"
                $handoffMode={handoffMode}
                style={{ marginTop: LAYOUT.GUTTER }}
                className={CSS_CLASSES.LOCATION_LIST.HANDOFF_TOGGLE}
              >
                <LocationsHandoffToggleButton
                  aria-selected={handoffMode === CONVEYANCE_TYPES.PICKUP}
                  role="tab"
                  aria-controls="non-delivery-tab"
                  $active={handoffMode === CONVEYANCE_TYPES.PICKUP}
                  onClick={() => setHandoffMode(CONVEYANCE_TYPES.PICKUP)}
                >
                  <StringAccessor
                    accessor="locations.search_tab_default_text"
                    html={true}
                    tag="span"
                  />
                </LocationsHandoffToggleButton>
                <LocationsHandoffToggleButton
                  aria-selected={handoffMode === CONVEYANCE_TYPES.DELIVERY}
                  role="tab"
                  aria-controls="delivery-tab"
                  $active={handoffMode === CONVEYANCE_TYPES.DELIVERY}
                  onClick={() => setHandoffMode(CONVEYANCE_TYPES.DELIVERY)}
                >
                  <StringAccessor
                    accessor="locations.search_tab_delivery_text"
                    html={true}
                    tag="span"
                  />
                </LocationsHandoffToggleButton>
              </LocationsHandoffToggle>
            </Render>

            {/* Delivery Search */}
            <Render condition={handoffMode === CONVEYANCE_TYPES.DELIVERY && deliverySearchEnabled}>
              <div role="tabpanel" id="delivery-tab">
                <LocationsDeliverySearch>
                  <DeliverySearch />
                </LocationsDeliverySearch>
              </div>
            </Render>

            <Render condition={handoffMode !== CONVEYANCE_TYPES.DELIVERY}>
              <div role="tabpanel" id="non-delivery-tab">
                {/* Normal List-View / Pickup Search */}
                <FixOnScroll
                  $fromTop={LAYOUT.MOBILE_HEADERHEIGHT}
                  id="LocationSearch"
                  minWidth={767}
                >
                  <Search
                    onSearch={onSearch}
                    onReset={handleViewAll}
                    params={params}
                    loading={searchLoading}
                    showViewAll={viewState !== LOCATION_VIEW_STATES.VIEW_ALL}
                    initialValues={{
                      distance: LOCATION_OPTIONS.defaultSearchRadius,
                    }}
                    locationViewState={viewState}
                    handoffMode={handoffMode}
                  />
                </FixOnScroll>

                <StyledHr />

                <Render condition={searchLoading || loading}>
                  <div>
                    <LoadingIndicator data-testid="loading-indicator" className="pad">
                      Searching for locations...
                    </LoadingIndicator>
                  </div>
                </Render>

                {/* Locations message derived from location data in this.deriveResults() */}
                <LocationsHeading
                  onSearch={onSearch}
                  isLoading={searchLoading}
                  locationViewState={viewState}
                  locations={list}
                  locationParams={params}
                  locationsMeta={meta}
                  clickedMapSearch={clickedMapSearch}
                />

                {/* Display a user's favorite locations
                    - If a user is logged in
                    - And has favorited locations
                    - And is not in the middle of an active search */}
                {/* Default toggle view only */}
                <LayoutListSection data-testid="locators-list-section">
                  {/* User or Geolocation search status messages */}
                  <Render condition={searchLoading}>
                    <LocationsNoResults>
                      Searching for {pluralizeString(organization.label)} in your area.
                    </LocationsNoResults>
                  </Render>

                  <Render condition={Boolean(params && list && !list.length && !searchLoading)}>
                    <LocationsNoResults>
                      <div style={{ paddingBottom: `${LAYOUT.GUTTER}px` }}>
                        <StringAccessor
                          accessor="locations.no_results"
                          html={true}
                          dataObj={{ brandName: organization.label }}
                        />
                      </div>

                      <LocationsViewAll onClick={handleViewAll}>
                        <StringAccessor
                          accessor="locations.all_locations_cta"
                          className={CSS_CLASSES.STORE_LOCATOR.VIEW_ALL_LOCATION_LINK}
                        />
                      </LocationsViewAll>
                    </LocationsNoResults>
                  </Render>

                  {/* Consolidated Results List */}
                  <ResultsList
                    activeLocationId={activeLocationId! ?? null}
                    locations={list}
                    setActiveLocation={setActiveLocation}
                    displayType={viewState}
                  />

                  <Render condition={moreLocationsLoading}>
                    <div>
                      <LoadingIndicator data-testid="loading-indicator">
                        Loading more locations...
                      </LoadingIndicator>
                    </div>
                  </Render>

                  <Render
                    condition={Boolean(
                      !moreLocationsLoading &&
                        meta &&
                        meta.pagination &&
                        currentPage < meta.pagination.total_pages,
                    )}
                  >
                    <LocationsViewAll onClick={() => getLocations(currentPage + 1)}>
                      View More Locations
                    </LocationsViewAll>
                  </Render>
                </LayoutListSection>
              </div>
            </Render>
          </LocationsLayoutList>

          {/* Map (found at: `apps/web-ordering/src/components/locations/map/google/index.tsx`) */}
          <LocationsLayoutMap>
            {/* Render map only if we're on desktop... */}
            {typeof window !== 'undefined' && !window.matchMedia(`(max-width: 767px)`).matches && (
              <ClientSideMap
                activeLocationId={activeLocationId}
                locations={list}
                setActiveLocation={setActiveLocation}
                locationSearchTimestamp={searchTimestamp}
                fetchCenterCoords={fetchCenterCoords}
                hideMapButton={hideMapButton}
                showMapButton={showMapButton}
              />
            )}
          </LocationsLayoutMap>
        </LocationsLayout>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  organization: state.app.organization.organization,
  activeLocationId: state.app.locations.activeLocationId,
  params: state.app.locations.params,
  meta: state.app.locations.meta,
  loading: state.app.locations.loading,
  searchLoading: state.app.locations.searchLoading,
  moreLocationsLoading: state.app.locations.moreLocationsLoading,
  searchTimestamp: state.app.locations.searchTimestamp,
  viewState: state.app.locations.viewState,
  list: state.app.locations.list,
  webConfig: state.app.cmsConfig.webConfig,
  data: state.app.me.data,
});

const mapDispatchToProps = {
  fetchLocations: locationsActions.fetchLocations,
  fetchAllLocations: locationsActions.fetchAllLocations,
  searchLocations: locationsActions.searchLocations,
  setActiveLocation: locationsActions.setActiveLocation,
  clearLocations: locationsActions.clearLocations,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default compose(connector, withRouter)(LocationsFinder);
