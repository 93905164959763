import { type LoyaltyState, type LoyaltyUser, type Strings } from '@koala/sdk';
import { type ConnectedProps, connect } from 'react-redux';
import { StyledRewardsSummaryHeader } from '../styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { type RootState } from '@/types/app';
import { pluralizeString, safelyGetString } from '@/utils/stringHelpers';

const rewardsSummaryStringHandler = (
  me: LoyaltyUser | null,
  loyaltyState: LoyaltyState | null,
  strings: Strings,
): { accessor: string; dataObj: object | null } => {
  // If not logged in
  if (!me?.id) {
    return {
      accessor: 'locations.loyalty_module_signed_out_header',
      dataObj: null,
    };
  }
  // If user has loyalty points
  if (loyaltyState?.points) {
    const pointsCounter = pluralizeString(
      safelyGetString(strings, 'rewards.rewards_counter'),
      loyaltyState.points > 1,
    );
    return {
      accessor: 'locations.loyalty_module_signed_in_header',
      dataObj: {
        points: loyaltyState.points.toLocaleString(),
        counter: pointsCounter,
      },
    };
  }

  // Logged in but no points
  return {
    accessor: 'locations.loyalty_module_signed_in_header_no_points',
    dataObj: null,
  };
};

const _RewardsSummaryHeader = ({ me, loyaltyState, strings }: ReduxProps) => {
  const { accessor, dataObj } = rewardsSummaryStringHandler(me, loyaltyState, strings);
  return (
    <StyledRewardsSummaryHeader>
      {/* @ts-expect-error differentiate between `null` and `undefined. */}
      <StringAccessor accessor={accessor} html={true} dataObj={dataObj} />
    </StyledRewardsSummaryHeader>
  );
};

const mapStateToProps = (state: RootState) => ({
  me: state.app.me.data,
  loyaltyState: state.app.me.loyaltyState,
  strings: state.app.cmsConfig.strings,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export const RewardsSummaryHeader = connector(_RewardsSummaryHeader);
