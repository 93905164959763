import { type Location, type LocationSearchQuery } from '@koala/sdk';
import get from 'lodash/get';
import styled, { useTheme } from 'styled-components';
import Label from '@/components/uielements/label';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { LOCATIONS_CONFIG, LOCATION_VIEW_STATES } from '@/constants/locations';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { locationActionTypes } from '@/redux/locations/actions';

interface Props {
  clickedMapSearch: boolean;
  isLoading: boolean;
  locations: Location[];
  locationsMeta: any;
  locationParams?: LocationSearchQuery | null;
  locationViewState: string;
  onSearch: (values?: LocationSearchQuery, previousAction?: string) => void;
}

export const LocationsHeading = (props: Props) => {
  const { locationViewState } = props;
  const theme = useTheme();
  const listDisplay = theme.locations.list_display;

  const toggleSearchRadius = (currentParams: { distance: number }) => (
    <>
      <Label visuallyHidden={true} htmlFor="search-radius">
        Select location radius to search by
      </Label>
      <StyledRadiusSelect
        id="search-radius"
        name="search-radius"
        onChange={(e: { target: any }) =>
          props.onSearch(
            { ...currentParams, distance: e.target.value },
            locationActionTypes.SEARCH_LOCATIONS,
          )
        }
        value={currentParams.distance}
      >
        <option value={1}>1 mile</option>
        <option value={5}>5 miles</option>
        <option value={10}>10 miles</option>
        <option value={15}>15 miles</option>
        <option value={20}>20 miles</option>
        <option value={25}>25 miles</option>
        <option value={30}>30 miles</option>
      </StyledRadiusSelect>
    </>
  );

  const deriveResults = () => {
    const { locations, locationParams, locationsMeta, isLoading, clickedMapSearch } = props;

    const SEARCH_HEADING = (
      <>
        Showing {locations ? locations.length : ''} locations within{' '}
        {locationParams ? toggleSearchRadius(locationParams) : ''} of{' '}
        {locationParams && locationParams.address
          ? decodeURIComponent(locationParams.address)
          : 'your location'}
        :
      </>
    );

    const ALL_HEADING =
      locations && locations.length ? (
        <>
          Showing {locations && locations.length} of {get(locationsMeta, 'pagination.total')}{' '}
          locations:
        </>
      ) : (
        <></>
      );
    const MAP_SEARCH_HEADING = (
      <>Showing {locations && locations.length} locations within your search area:</>
    );

    let locationsHeading = ALL_HEADING;

    if (!isLoading) {
      if (locations && locations.length && locationParams && !clickedMapSearch) {
        locationsHeading = SEARCH_HEADING;
      } else if (clickedMapSearch) {
        locationsHeading = MAP_SEARCH_HEADING;
      }
    }

    return locationsHeading;
  };

  return (
    <>
      {/*
        Locations message derived from location data in this.deriveResults()
      */}
      {(listDisplay !== LOCATIONS_CONFIG.ALL_DISPLAY.GROUP_BY_STATE ||
        locationViewState !== LOCATION_VIEW_STATES.VIEW_ALL) && (
        <StyledSearchHeading className={CSS_CLASSES.STORE_LOCATOR.SEARCH_HEADING}>
          {deriveResults()}
        </StyledSearchHeading>
      )}
    </>
  );
};

const StyledSearchHeading = styled.h4(({ theme }) => ({
  ...getFontStyles(theme.text.primary_font, ['font_size']),
  lineHeight: 1,
  margin: '20px 0',
}));

const StyledRadiusSelect = styled.select(({ theme }) => ({
  backgroundColor: theme.forms.background_color ?? 'transparent',
  border: `1px solid ${theme.forms.border_color ?? 'transparent'}`,
  borderRadius: `${theme.forms.border_radius ?? 0}px`,
  ...getFontStyles(theme.forms.font, ['color']),
}));
