import styled from 'styled-components';
import { SPACING_SCALE } from '@/constants/styles';

interface StyledHrProps {
  $background?: string;
}

export const StyledHr = styled.hr<StyledHrProps>`
  background-color: ${({ $background, theme }) => $background || theme.global.primary_border_color};
  display: block;
  height: 1px;
  border: 0;
  margin: ${SPACING_SCALE.MEDIUM}px 0;
  padding: 0;
`;
