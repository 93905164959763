import styled from 'styled-components';

export const FixedContainer = styled.div<{ $fromTop: any; $fixed: any }>(
  ({ $fixed, $fromTop }) =>
    $fixed && {
      left: 0,
      position: 'fixed',
      top: `${$fromTop}px`,
      width: '100%',
      zIndex: 1,
    },
);
