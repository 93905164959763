import Link from 'next/link';
import { useSelector } from 'react-redux';
import { StyledButtonContainer } from '../styles';
import LoyaltyAccessor from '@/components/account/loyaltyAccessor';
import { Terms } from '@/components/account/rewards/terms';
import { StyledLinkButton } from '@/components/account/styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { Modal } from '@/components/uielements/modal';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { LOYALTY_ACCESSOR_TYPES } from '@/constants/loyalty';
import { LOYALTY_ROUTES, ROUTES } from '@/constants/routes';
import { type RootState } from '@/types/app';
import { safelyGetString } from '@/utils/stringHelpers';

interface Props {
  showRewardsTerms: React.ReactNode | null;
}

export const RewardsSummaryButtons = ({ showRewardsTerms }: Props) => {
  const strings = useSelector((state: RootState) => state.app.cmsConfig.strings);
  const me = useSelector((state: RootState) => state.app.me.data);
  const howRewardsWorkExternalURL = safelyGetString(strings, 'rewards.learn_more_url');

  return (
    <StyledButtonContainer>
      {!me?.id ? (
        // If not logged in
        <>
          <Link href={ROUTES.SIGN_UP} passHref={true} legacyBehavior>
            <StyledLinkButton
              $bold={true}
              as="a"
              className={CSS_CLASSES.LOYALTY.LOCATIONS_REWARDS_SUMMARY_LINK}
            >
              <StringAccessor accessor="locations.loyalty_module_signed_out_join_cta" />
            </StyledLinkButton>
          </Link>
          {howRewardsWorkExternalURL && (
            <StyledLinkButton
              $bold={true}
              className={CSS_CLASSES.LOYALTY.LOCATIONS_REWARDS_SUMMARY_LINK}
              as="a"
              href={howRewardsWorkExternalURL}
              target="_blank"
            >
              <StringAccessor accessor="locations.loyalty_module_signed_out_learn_cta" />
            </StyledLinkButton>
          )}

          {showRewardsTerms && !howRewardsWorkExternalURL && (
            <Modal.Container
              trigger={(open) => (
                <StyledLinkButton
                  $bold={true}
                  onClick={open}
                  className={CSS_CLASSES.LOYALTY.LOCATIONS_REWARDS_SUMMARY_LINK}
                >
                  <StringAccessor accessor="locations.loyalty_module_signed_out_learn_cta" />
                </StyledLinkButton>
              )}
            >
              <Terms />
            </Modal.Container>
          )}
        </>
      ) : (
        // Only show link if logged in and brand supports a Rewards Page
        <LoyaltyAccessor
          checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
          checkName={LOYALTY_ROUTES.REWARDS}
          component={
            <Link href={LOYALTY_ROUTES.REWARDS} passHref={true} legacyBehavior>
              <StyledLinkButton
                $bold={true}
                as="a"
                className={CSS_CLASSES.LOYALTY.LOCATIONS_REWARDS_SUMMARY_LINK}
              >
                <StringAccessor accessor="locations.loyalty_module_signed_in_cta" />
              </StyledLinkButton>
            </Link>
          }
        />
      )}
    </StyledButtonContainer>
  );
};
