import { useEffect, useState, Children, cloneElement } from 'react';
import { FixedContainer } from './styles';

interface Props {
  id: string;
  $fromTop: number;
  minWidth?: number;
}

const FixOnScroll: React.FC<Props> = ({ id, $fromTop, minWidth, children }) => {
  const [elementHeight, setElementHeight] = useState<string | number>('');
  const [fixed, setFixed] = useState<boolean>(false);

  const handleScroll = () => {
    // Get 'fixed' element in order to derive height
    const element = document.getElementById(id);
    // Get 'fixed' elements' static container to derive position from top
    const elementContainer = document.getElementById(`${id}Container`);

    if (element && elementContainer) {
      const elementHeight = element.offsetHeight;
      const pos = elementContainer.getBoundingClientRect();
      const isFixed = (minWidth || 9999) >= window.innerWidth && pos.top < $fromTop;

      setElementHeight(elementHeight);
      setFixed(isFixed);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [$fromTop, id, minWidth]);

  const childrenWithProps = Children.map(children, (child: any) => cloneElement(child, { fixed }));

  return (
    <div id={`${id}Container`} style={{ height: (fixed && elementHeight) || 'auto' }}>
      <FixedContainer $fromTop={$fromTop} $fixed={fixed} id={id}>
        {childrenWithProps}
      </FixedContainer>
    </div>
  );
};

export default FixOnScroll;
