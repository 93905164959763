import styled from 'styled-components';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { LAYOUT } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface StyledSearchProps {
  $fixed: boolean;
}

export const StyledSearch = styled.div<StyledSearchProps>`
  padding: ${LAYOUT.GUTTER / 2}px 0 0;

  ${({ $fixed, theme }) =>
    $fixed &&
    `
    background: ${theme.global.body_color};
    border-bottom: 1px solid ${theme.global.primary_border_color};
    padding-left: ${LAYOUT.GUTTER}px;
    padding-right: ${LAYOUT.GUTTER}px;
  `}
`;

export const StyledForm = styled.form`
  display: flex;
  position: relative;

  > div {
    position: relative;
    flex-basis: 70%;
  }
`;

export const StyledSubmit = styled(StyledPrimaryButton)`
  flex-basis: 30%;
  margin-left: ${LAYOUT.GUTTER / 2}px;

  @media (max-width: 767px) {
    border-radius: ${({ theme }) =>
      `0 ${theme.buttons.primary_button_border_radius}px ${theme.buttons.primary_button_border_radius}px 0`};
    margin-left: 0px;
  }
`;

export const StyledLocationIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 40px;
  padding: 0 7px 0 10px;
  position: absolute;
  right: 0;
  top: 0;
  img {
    height: 20px;
  }
`;

export const StyledViewAll = styled.button(({ theme }) => ({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontFamily: theme.primary_font.medium.font_family,
  padding: 0,
  textDecoration: 'underline',
  ...getFontStyles(theme.text.primary_font, ['color']),

  '@media (max-width: 767px)': {
    display: 'none',
  },
}));
