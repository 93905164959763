import { ConnectedRewardsIcon } from '../uielements';
import { StyledLocationsRewardsSummary } from './styles';
import { COMPONENT_CONFIG } from '@/brand-components';
import { RewardsSummaryButtons } from '@/components/account/locationRewardsSummary/buttons';
import { RewardsSummaryHeader } from '@/components/account/locationRewardsSummary/header';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { useSelector } from '@/redux';

export function LocationsRewardsSummary() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  const REWARDS_TERMS_CONTENT = COMPONENT_CONFIG.MODAL_HOW_REWARDS_WORK[slug];

  return (
    <>
      <StyledLocationsRewardsSummary className={CSS_CLASSES.LOYALTY.LOCATIONS_REWARDS_SUMMARY}>
        <ConnectedRewardsIcon />
        <div>
          <RewardsSummaryHeader />
          <RewardsSummaryButtons showRewardsTerms={REWARDS_TERMS_CONTENT} />
        </div>
      </StyledLocationsRewardsSummary>
    </>
  );
}
