import * as React from 'react';
import { reduxForm } from 'redux-form';
import type { InjectedFormProps } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { type DeliveryAddress } from '@koala/sdk';
import { DeliverySearchStoredAddresses } from '../storedAddresses';
import GoogleAutocomplete from '@/components/fulfillmentManager/deliveryManager/deliveryForm/autocomplete';
import { locationsActions } from '@/redux/locations/actions';
import { type RootState } from '@/types/app';

interface AdditionalProps {
  apiKey?: string;
  loading?: boolean;
  searchDeliveryCoverage?: (address: DeliveryAddress) => void;
}

type Props = InjectedFormProps<any, AdditionalProps> & AdditionalProps;

const noop = (address: DeliveryAddress) => {
  console.log(`No operation performed with address:`, address);
};

const BaseDeliverySearch: React.FC<Props> = ({
  handleSubmit,
  apiKey = '',
  loading = false,
  searchDeliveryCoverage,
}) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <GoogleAutocomplete
          loading={!!loading}
          apiKey={apiKey || ''}
          checkOrSearchDeliveryCoverage={searchDeliveryCoverage ?? noop}
        />
        <DeliverySearchStoredAddresses />
      </form>
    </>
  );
};

function useDeliverySearchProps() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.app.locations.searchLoading);

  const searchDeliveryCoverage = (address: DeliveryAddress) => {
    dispatch(locationsActions.searchDeliveryCoverage(address));
  };

  return { loading, apiKey: theme.locations.map_key, searchDeliveryCoverage };
}

const DeliverySearch = reduxForm<any, AdditionalProps>({
  form: 'delivery-search',
})((props) => {
  const { loading, apiKey, searchDeliveryCoverage } = useDeliverySearchProps();
  return (
    <BaseDeliverySearch
      {...props}
      loading={loading}
      apiKey={apiKey}
      searchDeliveryCoverage={searchDeliveryCoverage}
    />
  );
});

export default DeliverySearch;
