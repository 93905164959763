import styled from 'styled-components';
import { COLORS } from '@/constants/styles';

export const StyledAnimatedBlock = styled.div`
  overflow: hidden;
  @keyframes barberpolebackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 101px 0;
    }
  }

  &:after {
    height: 100%;
    width: 2050px;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;

    transition: opacity 0.3s ease;
    background: repeating-linear-gradient(
      -55deg,
      ${({ theme }) => theme.global.loading_bar_color} 1px,
      ${COLORS.PRIMARY_NAVY_XX_LIGHT} 2px,
      ${COLORS.PRIMARY_NAVY_XX_LIGHT} 11px,
      ${({ theme }) => theme.global.loading_bar_color} 12px,
      ${({ theme }) => theme.global.loading_bar_color} 22px
    );

    animation-name: barberpolebackground;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`;
