import { useEffect, type FC } from 'react';
import { type WebConfig, fallbackStrings } from '@koala/sdk';
import { compose } from 'redux';
import { Field, reduxForm, type InjectedFormProps } from 'redux-form';
import { withTheme } from 'styled-components';
import { StyledSearch, StyledForm, StyledSubmit, StyledViewAll } from './styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { SearchInput } from '@/components/uielements/searchInput';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { LOCATION_VIEW_STATES, LOCATION_OPTIONS } from '@/constants/locations';
import { type LocationsParams } from '@/types/locations';
import { safelyGetString } from '@/utils/stringHelpers';

interface ThemeProps {
  theme: WebConfig;
}
interface SearchProps extends InjectedFormProps<any>, ThemeProps {
  fixed?: boolean;
  loading: boolean;
  params?: LocationsParams;
  showViewAll: boolean;
  onReset?: () => void;
  onSearch: (values?: object) => void;
  locationViewState: LOCATION_VIEW_STATES;
}

interface Props extends InjectedFormProps<any, SearchProps>, ThemeProps {
  fixed?: boolean;
  loading: boolean;
  params?: LocationsParams;
  showViewAll: boolean;
  onReset?: () => void;
  onSearch: (values?: object) => void;
  locationViewState: LOCATION_VIEW_STATES;
}

const Search: FC<Props> = ({
  reset,
  handleSubmit,
  onSearch,
  onReset,
  showViewAll,
  fixed = false,
  loading,
  params,
  locationViewState,
}) => {
  useEffect(() => {
    if (!params) {
      reset();
    }
  }, [params]);

  return (
    <StyledSearch $fixed={fixed}>
      <StyledForm
        onSubmit={handleSubmit((values) => {
          onSearch({ distance: LOCATION_OPTIONS.widerSearchRadius, ...values });
        })}
      >
        <Field
          name="address"
          // use fallBack strings, to use the hardcoded field rather than config's value.
          placeholder={safelyGetString(fallbackStrings, 'locations.search_placeholder')}
          component={SearchInput}
          {...{
            icon: '/static/img/icons/search.svg',
          }}
        />

        <StyledSubmit
          type="submit"
          id="Search__search-btn"
          data-testid="search-address-submit-button"
          size="small"
        >
          <StringAccessor
            accessor={loading ? 'locations.search_cta_loading' : 'locations.search_cta'}
          />
        </StyledSubmit>
      </StyledForm>

      {showViewAll && locationViewState !== LOCATION_VIEW_STATES.VIEW_ALL && (
        <StyledViewAll
          data-testid="view-all-locations-link"
          onClick={onReset}
          className={CSS_CLASSES.STORE_LOCATOR.VIEW_ALL_LOCATION_LINK}
        >
          <StringAccessor accessor="locations.all_locations_cta" />
        </StyledViewAll>
      )}
    </StyledSearch>
  );
};

export default compose<any>(
  withTheme,
  reduxForm<any, Props>({
    form: 'locations-search',
    validate: (values: { address: string }) => {
      const errors: Record<string, string> = {};
      if (!values.address) {
        errors.address = 'Zip code or address required';
      }
      return errors;
    },
  }),
)(Search);
