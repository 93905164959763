import { CONVEYANCE_TYPES } from '@koala/sdk';
import { readableColor } from 'polished';
import styled from 'styled-components';
import AnimatedBlockComponent from '@/components/uielements/animatedBlock';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { LAYOUT, ORDER } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

export const LoadingIndicator = styled(AnimatedBlockComponent)`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.global.loading_bar_color};
  border-radius: 5px;
  font-size: 16px;
  color: white;
  padding: 12px ${LAYOUT.GUTTER}px;
  z-index: ${ORDER.BARBER_LOADING};
  .small {
    font-size: 12px;
    padding: 3px;
  }
  .pad {
    margin-bottom: 20px;
  }
`;

export const LocationsLayout = styled.div`
  display: flex;
  flex-direction: ${({ theme }) =>
    theme.locations.map_position === 'left' ? 'row-reverse' : 'row'};
  min-height: 100vh;
  position: relative;

  @media (max-width: 767px) {
    display: block;
    position: static;
  }
`;

export const LocationsLayoutList = styled.div(({ theme }) => ({
  padding: '40px 20px 20px',
  width: '500px',
  '> h1': {
    margin: 0,
    ...getFontStyles(theme.location_search.secondary_font),
  },
  '> div': {
    ...getFontStyles(theme.text.primary_font, ['font_size']),
  },
  '@media (max-width: 1024px)': {
    width: '50%',
  },
  '@media (max-width: 767px)': {
    padding: '20px 20px 0',
    width: '100%',
    '> h1': { marginBottom: '10px' },
  },
}));

export const LayoutListSection = styled.div`
  display: block;

  > div {
    > h3 {
      color: rgba(48, 62, 71, 0.5);
      font-family: ${({ theme }) => theme.primary_font.bold.font_family};
      font-size: 12px;
      margin: 30px 0 20px;
    }
  }
`;

export const LocationsLayoutMap = styled.div`
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: calc(100% - 500px);
  z-index: ${ORDER.MAP};

  // Determine map position
  ${({ theme }) =>
    theme.locations.map_position === 'right'
      ? `
    right: 0;
  `
      : `
    left: 0;
  `};

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    height: calc(
      100vh - ${LAYOUT.MOBILE_HEADERHEIGHT}px - ${LAYOUT.MOBILE_MAP_LOCATION_CARD_HEIGHT}px -
        ${LAYOUT.MOBILE_LIST_MAP_FOOTER_HEIGHT}px
    );
    opacity: 0;
    position: fixed;
    top: ${LAYOUT.MOBILE_HEADERHEIGHT}px;
    width: 100%;
    z-index: -1;
  }
`;

export const LocationsNoResults = styled.div`
  font-family: ${({ theme }) => theme.primary_font.medium.font_family};
  margin: ${LAYOUT.GUTTER}px 0 12px 0;

  @media (max-width: 767px) {
    margin-top: 25px;
  }
`;

export const LocationsViewAll = styled(StyledPrimaryButton)`
  margin: 0 0 ${LAYOUT.GUTTER}px;
  width: 100%;
`;

interface LocationsHandoffToggleProps {
  $handoffMode: CONVEYANCE_TYPES;
}

export const LocationsHandoffToggle = styled.div<LocationsHandoffToggleProps>`
  display: flex;
  position: relative;
  height: 52px;

  // Inactive state
  background-color: transparent;

  ${({ $handoffMode, theme }) => `
    border-radius: ${theme.global.border_radius}px;
    border: 1px solid ${theme.buttons.secondary_button_border_color};

    &:after {
      // Active state
      box-sizing: border-box; //include border size into calculation of width and height
      border-radius: calc(${
        theme.global.border_radius
      }px - var(--size-1)); // --size-1 is distance between button and outer div
      border: 1px solid ${theme.buttons.secondary_button_border_color};
      background: ${theme.buttons.secondary_button_background_color};
      content: "";
      position: absolute;
      transition: all 0.2s linear;
      left: var(--size-1);
      bottom: var(--size-1);
      height: calc(100% - var(--size-2)); // --size-2 is needed as we calculate height based on height of outer element - (bottom + top gap size)
      width: calc(50% - var(--size-1));

      ${$handoffMode === CONVEYANCE_TYPES.DELIVERY ? 'left: 50%;' : ''}
    }
  `}

  @media (max-width: 767px) {
    justify-content: center;
    max-width: 100%;
  }
`;

export const LocationsHandoffToggleButton = styled.button<{ $active?: boolean }>(
  ({ theme, $active = false }) => ({
    all: 'unset',
    outline: 'revert',
    cursor: 'pointer',
    margin: 'var(--size-1)',
    textAlign: 'center',
    width: '100%',
    ...getFontStyles(theme.buttons.primary_font, [
      'font_family',
      'font_style',
      'font_weight',
      'line_height',
    ]),

    span: {
      color: $active
        ? safePolished(readableColor)(theme.buttons.secondary_button_background_color)
        : 'inherit',
      position: 'relative',
      zIndex: 1,
    },
  }),
);

//   ${({}) =>
//     `
//     ${
//       // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
//       active &&
//       `
//       span {

//       }
//     `
//     }
//   `}
// `;

export const LocationsReorders = styled.div(({ theme }) => ({
  margin: '0 0 16px',
  '.koala__ui-reorders-container-div': {
    '& > div': { marginBottom: 0 },
  },
  h2: {
    ...getFontStyles(theme.location_search.tertiary_font, ['color', 'font_family', 'font_size']),
  },
  ul: {
    overflowX: 'scroll',
    margin: 'var(--size-4) var(--neg-size-5) var(--size-4) var(--neg-size-5)',
    gap: '8px',
  },
  li: {
    borderRadius: `${theme.location_card.border_radius ?? 0}px`,
    width: 'auto',
  },
}));

export const LocationsDeliverySearch = styled.div`
  padding: ${LAYOUT.GUTTER / 2}px 0 0;
`;
